<template>
  <Layout>
    <div class="noadscontainer">
      <div class="logo neo" />
      <img
        v-if="orientation !== 'landscape'"
        class="header"
        alt="Mythic Legends"
        :src="require('../../assets/images/ml_image.png')"
      >
      <h1 class="title">
        {{ content.noads_title }}
      </h1>
      <div class="na_text">
        <span v-html="content.noads_text" />
        <ExternalLink
          :url="o7policyUrl"
          :text="content.noads_text2"
        />
        <span v-html="content.noads_text3" />
      </div>
      <Footer
        module="noads"
        screen="noads"
      />
    </div>
  </Layout>
</template>

<script>
import Footer from '@components/Footer.vue'
import ExternalLink from '@components/ExternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Footer,
    ExternalLink,
  },
  data() {
    return {
      content: {},
      orientation: '',
      o7policyUrl: '',
    }
  },
  created() {
    this.$compliance.initCollector('consent_noads')
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation

    // request Outfit7 policy url
    this.$compliance.privacyUrl('outfit7').then((pp) => {
      this.o7policyUrl = pp
    })
  },
}
</script>

<style lang="scss">
.noadscontainer {
  background: #241720;
  width: 100%;
  height: 100%;

  .logo {
    width: 52px;
    height: 34px;
    max-width: 130px;
    margin: 0 auto 0 auto;
    &.neo {
      width: 88px;
      background-image: url('../../assets/images/o7neo-logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .header {
    display: block;
    width: calc(100% - 100px);
    max-width: 460px;
    height: auto;
    margin: 0 auto;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    font-size: 1.8em;
    line-height: 1em;
    color: #f3bf76;
    text-align: center;
    text-transform: uppercase;
    @media screen and (min-width: 460px) {
      font-size: 2em;
    }
    @media screen and (min-width: 768px) {
      font-size: 3em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .na_text {
    padding: 0 0 10px 0;
    font-size: 0.8em;
    color: #eadacb;
    text-align: center;
    margin: 0 0 145px 0;
    .external_link {
      .span {
        color: #f3bf76;
        text-decoration: underline;
        text-transform: none;
      }
    }
    @media screen and (min-width: 375px) {
      font-size: 0.9em;
    }
    @media screen and (min-width: 460px) {
      font-size: 1em;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.4em;
      padding: 0 30px 20px 30px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.8em;
    }
  }
  .footer {
    background: #241720;
    box-shadow: 0px -10px 16px 16px #241720;
    .global_consent_options .btn {
      color: #614c49;
      &.green {
        background-image: url('../../assets/images/button-green.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.grey {
        background-image: url('../../assets/images/button-sand.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      & span {
        margin-top: 0;
      }
    }
  }
  .external_link .span {
    color: #f3bf76;
  }
}
.landscape .noadscontainer .na_text {
  margin: 0;
}
.es .noadscontainer .title,
.pt .noadscontainer .title,
.de .noadscontainer .title,
.fr .noadscontainer .title,
.ru .noadscontainer .title,
.ja .noadscontainer .title,
.ko .noadscontainer .title {
  font-size: 1.2em;
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 5em;
  }
}
.es .noadscontainer .na_text,
.pt .noadscontainer .na_text,
.de .noadscontainer .na_text,
.fr .noadscontainer .na_text,
.ru .noadscontainer .na_text,
.ja .noadscontainer .na_text,
.ko .noadscontainer .na_text {
  font-size: 0.7em;
  @media screen and (min-width: 375px) {
    font-size: 0.8em;
  }
  @media screen and (min-width: 460px) {
    font-size: 0.9em;
  }
}
.es .noadscontainer .pp_text,
.pt .noadscontainer .pp_text,
.de .noadscontainer .pp_text,
.fr .noadscontainer .pp_text,
.ru .noadscontainer .pp_text,
.ja .noadscontainer .pp_text,
.ko .noadscontainer .pp_text {
  font-size: 0.9em;
  @media screen and (min-width: 768px) {
    font-size: 1.4em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.8em;
  }
}
</style>
